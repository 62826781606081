define([
    'lodash',
    'componentsCore',
    'santa-components'
], function (
    _,
    componentsCore,
    santaComponents
) {
    'use strict';

    function getToggleState(componentPreviewState, isTargetOpen) {
        if (componentPreviewState) {
            return componentPreviewState === 'open' ? 'toggleOpen' : 'toggleClosed';
        }
        return isTargetOpen ? 'toggleOpen' : 'toggleClosed';
    }

    return {
        displayName: 'InlinePopupToggle',
        mixins: [componentsCore.mixins.skinBasedComp],
        propTypes: {
            isTargetOpen: santaComponents.santaTypesDefinitions.InlinePopup.isTargetOpen,
            style: santaComponents.santaTypesDefinitions.Component.style
        },
        getRootStyle() {
            const rootStyle = {zIndex: 1005};
            const position = _.isFunction(this.getRootPosition) ? {
                position: this.getRootPosition(this.props.style)
            } : {};
            return _.assign(rootStyle, position);
        },
        componentDidUpdate(prevProps) {
            if (this.props.isTargetOpen !== prevProps.isTargetOpen && this.props.isTargetOpen === false) {
                if (this.refs['']) {
                    this.refs[''].focus();
                }
            }
        },
        getSkinProperties() {
            const {componentPreviewState, isTargetOpen} = this.props;
            const toggleState = getToggleState(componentPreviewState, isTargetOpen);

            return {
                '': {
                    id: this.props.id,
                    key: `${this.props.id}_${this.props.skin}`,
                    style: this.getRootStyle(),
                    'role': 'button',
                    'aria-haspopup': 'dialog',
                    'aria-label': `${isTargetOpen ? 'close' : 'open'} navigation dialog`,
                    tabIndex: 0,
                    onKeyDown: componentsCore.utils.accessibility.keyboardInteractions.activateBySpaceOrEnterButton
                },

                buttonContainer: {
                    className: this.classSet({[toggleState]: true})
                },

                toggleButton: {
                    className: this.classSet({[toggleState]: true}),
                    'data-toggle-state': toggleState
                }
            };
        }
    };
});
