define([
    'lodash',
    'inlinePopup/components/inlinePopupToggle'
], function (
    _,
    inlinePopupToggle
) {
    'use strict';

    return _.assign({}, inlinePopupToggle, {displayName: 'MenuToggle'});
});
