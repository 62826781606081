define([
    'inlinePopup/components/inlinePopup',
    'inlinePopup/components/inlinePopupToggle',
    'inlinePopup/components/menuContainer',
    'inlinePopup/components/menuToggle',
    'skins',
    'inlinePopup/skins/skins.json'
], function (
    InlinePopup,
    InlinePopupToggle,
    MenuContainer,
    MenuToggle,
    skinsPackage,
    skinsJson
) {
    'use strict';

    skinsPackage.skinsMap.addBatch(skinsJson);

    return {
        InlinePopup,
        InlinePopupToggle,
        MenuContainer,
        MenuToggle
    };
});
