(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.viewer.skins.inlinePopup.DefaultInlinePopup'] = {
  "react": [
    [
      "div",
      "overlay",
      [],
      {}
    ],
    [
      "div",
      "container",
      [],
      {},
      [
        "div",
        "background",
        [],
        {}
      ],
      [
        "div",
        "inlineContentParent",
        [],
        {},
        [
          "div",
          "inlineContent",
          [],
          {
            "role": "dialog",
            "aria-label": "navigation dialog"
          }
        ]
      ]
    ]
  ],
  "params": {
    "bg": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "bg": "color_15"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%:not([data-is-mesh]) %inlineContent,%:not([data-is-mesh]) %container": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%overlay": "height:100%;width:100%;position:fixed;top:0;left:0;background-color:[bg];",
    "%inlineContentParent": "overflow-x:hidden;overflow-y:scroll;  scrollbar-width:none;  overflow:-moz-scrollbars-none;  -ms-overflow-style:none;",
    "%inlineContentParent::-webkit-scrollbar": "width:0;height:0;"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin1'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "span",
        "toggleButton",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%toggleButton": "display:block;width:22px;height:22px;position:relative;cursor:pointer;-webkit-transition:all 0.3s linear;transition:all 0.3s linear;border-radius:50%;",
    "%toggleButton:before,%toggleButton:after": "content:'';position:absolute;background:[lineColor];left:0;top:0;right:0;bottom:0;margin:auto;border-radius:5px;",
    "%toggleButton:before": "width:22px;height:3px;",
    "%toggleButton:after": "width:22px;height:3px;-webkit-transform:rotate(90deg);transform:rotate(90deg);-webkit-transition:all 0.12s linear;transition:all 0.12s linear;",
    "%toggleButton%_toggleOpen": "-webkit-transform:rotate(180deg);transform:rotate(180deg);",
    "%toggleButton%_toggleOpen:before": "background:[lineColorOpen];",
    "%toggleButton%_toggleOpen:after": "-webkit-transform:rotate(180deg);transform:rotate(180deg);background:[lineColorOpen];"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin10'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_icon",
          "_menu6"
        ],
        {},
        [
          "span",
          null,
          [
            "_line"
          ],
          {}
        ],
        [
          "span",
          null,
          [
            "_line"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_menu6": "cursor:pointer;position:relative;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;-webkit-transition:0.2s ease-in-out;transition:0.2s ease-in-out;width:22px;-webkit-tap-highlight-color:rgba(0, 0, 0, 0);display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%_menu6 span": "background-color:[lineColor];border-radius:2em;-webkit-backface-visibility:hidden;backface-visibility:hidden;height:3px;position:absolute;-webkit-transition:0.2s ease-in-out;transition:0.2s ease-in-out;width:100%;-webkit-tap-highlight-color:rgba(0, 0, 0, 0);left:0;",
    "%_menu6 span:nth-child(2)": "-webkit-transform:rotate(90deg);transform:rotate(90deg);-webkit-transform:rotate(90deg);transform:rotate(90deg);",
    "%_toggleOpen%_menu6": "-webkit-transform:rotate(135deg);transform:rotate(135deg);-webkit-transform:rotate(135deg);transform:rotate(135deg);",
    "%_toggleOpen%_menu6 span": "background:[lineColorOpen];background:[lineColorOpen];"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin11'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_menu"
        ],
        {},
        [
          "div",
          null,
          [
            "_top"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_mid"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_bottom"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_menu": "display:block;width:26px;height:20px;position:relative;cursor:pointer;",
    "%_menu div": "position:absolute;height:2px;border-radius:2px;background:[lineColor];-webkit-transition:width 0.2s ease 0.2s, -webkit-transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6);transition:width 0.2s ease 0.2s, -webkit-transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6);transition:transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6), width 0.2s ease 0.2s;transition:transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6), width 0.2s ease 0.2s, -webkit-transform 0.45s cubic-bezier(0.9, -0.6, 0.3, 1.6);",
    "%_menu %_mid": "top:11px;left:0;margin:-2px 0 0 0;width:26px;-webkit-transform-origin:50% 50%;transform-origin:50% 50%;",
    "%_menu %_top": "left:0;width:13px;-webkit-transform-origin:0 50%;transform-origin:0 50%;",
    "%_menu %_bottom": "bottom:0;width:18px;-webkit-transform-origin:100% 50%;transform-origin:100% 50%;",
    "%_menu%_toggleOpen %_mid": "-webkit-transform:rotate(-45deg) translate(0px, 0px);transform:rotate(-45deg) translate(0px, 0px);-webkit-transition:-webkit-transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s;transition:-webkit-transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s;transition:transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s;transition:transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s, -webkit-transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s;background:[lineColorOpen];",
    "%_menu%_toggleOpen %_top": "width:13px;-webkit-transform:translate(4px, 0px) rotate(45deg);transform:translate(4px, 0px) rotate(45deg);-webkit-transition:width 0.15s ease, -webkit-transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s;transition:width 0.15s ease, -webkit-transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s;transition:transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s, width 0.15s ease;transition:transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s, width 0.15s ease, -webkit-transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s;background:[lineColorOpen];",
    "%_menu%_toggleOpen %_bottom": "width:13px;-webkit-transform:translate(9px, 0px) rotate(45deg);transform:translate(9px, 0px) rotate(45deg);background:[lineColorOpen];-webkit-transition:width 0.15s ease, -webkit-transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s;transition:width 0.15s ease, -webkit-transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s;transition:transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s, width 0.15s ease;transition:transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s, width 0.15s ease, -webkit-transform 0.2s cubic-bezier(0.9, -0.6, 0.3, 1.6) 0.1s;"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin12'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_menu-button"
        ],
        {},
        [
          "div",
          null,
          [
            "_menu-button__line"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_menu-button__line"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_menu-button__line"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_menu-button": "position:absolute;cursor:pointer;",
    "%_menu-button__line": "height:2px;width:19px;margin-bottom:6px;background:[lineColor];position:relative;-webkit-transition:all 0.3s cubic-bezier(0, 1, 0.5, 1);transition:all 0.3s cubic-bezier(0, 1, 0.5, 1);",
    "%_menu-button__line:first-child": "left:0;top:0;-webkit-transform:rotate(0);transform:rotate(0);",
    "%_toggleOpen %_menu-button__line:first-child": "background:[lineColorOpen];left:0;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);top:8px;",
    "%_menu-button__line:nth-child(2)": "right:-5px;opacity:1;",
    "%_toggleOpen %_menu-button__line:nth-child(2)": "background:[lineColorOpen];right:0;opacity:0;",
    "%_menu-button__line:last-child": "left:0px;top:0;margin:0;-webkit-transform:rotate(0);transform:rotate(0);",
    "%_toggleOpen %_menu-button__line:last-child": "left:0;top:-8px;-webkit-transform:rotate(45deg);transform:rotate(45deg);background:[lineColorOpen];",
    "%_toggleOpen %_menu-button__line": "background:[lineColorOpen];"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin13'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_btn"
        ],
        {},
        [
          "div",
          null,
          [
            "_line",
            "_tp"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_line",
            "_ct"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_line",
            "_bt"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_btn": "width:26px;height:18px;",
    "%_line": "height:2px;border-radius:4px;background:[lineColor];position:relative;-webkit-transition:0.4s;transition:0.4s;",
    "%_tp": "width:26px;top:0px;",
    "%_ct": "width:26px;top:6px;",
    "%_bt": "width:13px;top:12px;",
    "%_toggleOpen %_tp": "background:[lineColorOpen];top:8px;",
    "%_toggleOpen %_ct": "background:[lineColorOpen];",
    "%_toggleOpen %_bt": "background:[lineColorOpen];top:4px;"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin14'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_menu-icon"
        ],
        {},
        [
          "span",
          null,
          [],
          {}
        ],
        [
          "span",
          null,
          [],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_menu-icon": "margin:auto;position:absolute;width:22px;height:22px;",
    "%_menu-icon span": "position:relative;display:block;width:22px;height:2px;background-color:[lineColor];-webkit-transition:top 0.2s cubic-bezier(0.3, 1.4, 0.7, 1) 0.2s, bottom 0.2s cubic-bezier(0.3, 1.4, 0.7, 1) 0.2s, -webkit-transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);transition:top 0.2s cubic-bezier(0.3, 1.4, 0.7, 1) 0.2s, bottom 0.2s cubic-bezier(0.3, 1.4, 0.7, 1) 0.2s, -webkit-transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), top 0.2s cubic-bezier(0.3, 1.4, 0.7, 1) 0.2s, bottom 0.2s cubic-bezier(0.3, 1.4, 0.7, 1) 0.2s;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94), top 0.2s cubic-bezier(0.3, 1.4, 0.7, 1) 0.2s, bottom 0.2s cubic-bezier(0.3, 1.4, 0.7, 1) 0.2s, -webkit-transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);",
    "%_menu-icon span:first-of-type": "top:5px;",
    "%_menu-icon span:last-of-type": "top:13px;",
    "%_menu-icon%_toggleOpen span": "-webkit-transition:top 0.2s cubic-bezier(0.3, 1.4, 0.7, 1), bottom 0.2s cubic-bezier(0.3, 1.4, 0.7, 1), -webkit-transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;transition:top 0.2s cubic-bezier(0.3, 1.4, 0.7, 1), bottom 0.2s cubic-bezier(0.3, 1.4, 0.7, 1), -webkit-transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s, top 0.2s cubic-bezier(0.3, 1.4, 0.7, 1), bottom 0.2s cubic-bezier(0.3, 1.4, 0.7, 1);transition:transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s, top 0.2s cubic-bezier(0.3, 1.4, 0.7, 1), bottom 0.2s cubic-bezier(0.3, 1.4, 0.7, 1), -webkit-transform 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.2s;",
    "%_menu-icon%_toggleOpen span:first-of-type": "-webkit-transform:rotate(45deg);transform:rotate(45deg);background-color:[lineColorOpen];top:10px;",
    "%_menu-icon%_toggleOpen span:last-of-type": "-webkit-transform:rotate(-45deg);transform:rotate(-45deg);background-color:[lineColorOpen];top:8px;"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin15'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_menu-button"
        ],
        {},
        [
          "div",
          null,
          [
            "_top",
            "_bar",
            "_left"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_top",
            "_bar",
            "_right"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_middle",
            "_bar"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_bottom",
            "_bar",
            "_left"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_bottom",
            "_bar",
            "_right"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_menu-button": "position:absolute;width:22px;height:19px;",
    "%_bar": "-webkit-transition:all 0.25s ease;transition:all 0.25s ease;height:3px;background-color:[lineColor];position:absolute;",
    "%_top": "top:0;width:50%;",
    "%_middle": "width:100%;top:8px;",
    "%_bottom": "width:50%;bottom:0;",
    "%_left": "left:0;",
    "%_right": "right:0;",
    "%_top%_bar%_left": "-webkit-transform-origin:0% 0%;transform-origin:0% 0%;",
    "%_top%_bar%_right": "-webkit-transform-origin:100% 0%;transform-origin:100% 0%;",
    "%_bottom%_bar%_left": "-webkit-transform-origin:0% 100%;transform-origin:0% 100%;",
    "%_bottom%_bar%_right": "-webkit-transform-origin:100% 100%;transform-origin:100% 100%;",
    "%_menu-button%_toggleOpen %_top%_bar%_left": "-webkit-transform:translate(5px, 2px) rotate(45deg);transform:translate(5px, 2px) rotate(45deg);background-color:[lineColorOpen];",
    "%_menu-button%_toggleOpen %_top%_bar%_right": "-webkit-transform:translate(-5px, 2px) rotate(-45deg);transform:translate(-5px, 2px) rotate(-45deg);background-color:[lineColorOpen];",
    "%_menu-button%_toggleOpen %_bottom%_bar%_left": "-webkit-transform:translate(5px, -1px) rotate(-45deg);transform:translate(5px, -1px) rotate(-45deg);background-color:[lineColorOpen];",
    "%_menu-button%_toggleOpen %_bottom%_bar%_right": "-webkit-transform:translate(-5px, -1px) rotate(45deg);transform:translate(-5px, -1px) rotate(45deg);background-color:[lineColorOpen];",
    "%_menu-button%_toggleOpen %_middle%_bar": "-webkit-transform:scaleX(0);transform:scaleX(0);"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin16'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_menu-button-2"
        ],
        {},
        [
          "div",
          null,
          [
            "_top-2",
            "_bar"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_middle-2",
            "_bar"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_bottom-2",
            "_bar"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "color_15",
    "lineColorOpen": "color_15"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_menu-button-2": "position:absolute;width:22px;height:20px;",
    "%_bar": "-webkit-transition:all 0.25s ease;transition:all 0.25s ease;height:2px;background-color:[lineColor];position:absolute;",
    "%_top-2": "top:0;width:100%;",
    "%_middle-2": "width:100%;top:9px;",
    "%_bottom-2": "width:100%;bottom:0;",
    "%_top-2%_bar": "-webkit-transform-origin:0% 0%;transform-origin:0% 0%;",
    "%_bottom-2%_bar": "-webkit-transform-origin:0% 100%;transform-origin:0% 100%;",
    "%_menu-button-2%_toggleOpen %_top-2%_bar": "-webkit-transform:translate(2px, 0px) rotate(45deg);transform:translate(4px, 2px) rotate(45deg);background-color:[lineColorOpen];",
    "%_menu-button-2%_toggleOpen %_bottom-2%_bar": "-webkit-transform:translate(4px, -1px) rotate(-45deg);transform:translate(4px, -1px) rotate(-45deg);background-color:[lineColorOpen];",
    "%_menu-button-2%_toggleOpen %_middle-2%_bar": "-webkit-transform:scaleX(0);transform:scaleX(0);background-color:[lineColorOpen];"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin2'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_wrapper-menu"
        ],
        {},
        [
          "div",
          null,
          [
            "_line-menu",
            "_half",
            "_start"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_line-menu",
            "_mid"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_line-menu",
            "_half",
            "_end"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_wrapper-menu": "width:26px;height:21px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-box-pack:justify;-webkit-justify-content:space-between;justify-content:space-between;cursor:pointer;-webkit-transition:-webkit-transform 330ms ease-out;transition:-webkit-transform 330ms ease-out;transition:transform 330ms ease-out;transition:transform 330ms ease-out, -webkit-transform 330ms ease-out;",
    "%_wrapper-menu%_toggleOpen": "-webkit-transform:rotate(-45deg);transform:rotate(-45deg);",
    "%_line-menu": "background-color:[lineColor];border-radius:1.5px;width:100%;height:3px;",
    "%_line-menu%_half": "width:50%;",
    "%_line-menu%_start": "-webkit-transition:-webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);transition:-webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);transition:transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);transition:transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57), -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);-webkit-transform-origin:right;transform-origin:right;",
    "%_toggleOpen %_line-menu%_start": "-webkit-transform:rotate(-90deg) translateX(4px);transform:rotate(-90deg) translateX(4px);background-color:[lineColorOpen];",
    "%_line-menu%_end": "-webkit-align-self:flex-end;align-self:flex-end;-webkit-transition:-webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);transition:-webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);transition:transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);transition:transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57), -webkit-transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);-webkit-transform-origin:left;transform-origin:left;",
    "%_toggleOpen %_line-menu%_end": "-webkit-transform:rotate(-90deg) translateX(-4px);transform:rotate(-90deg) translateX(-4px);background-color:[lineColorOpen];",
    "%_toggleOpen %_line-menu%_mid": "background-color:[lineColorOpen];"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin3'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_menu-button-3"
        ],
        {},
        [
          "div",
          null,
          [
            "_top-3",
            "_bar",
            "_left-3"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_top-3",
            "_bar",
            "_right-3"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_middle-3",
            "_bar"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_middle-4",
            "_bar"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_bottom-3",
            "_bar",
            "_left-3"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_bottom-3",
            "_bar",
            "_right-3"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_menu-button-3": "position:absolute;width:22px;height:20px;",
    "%_bar": "-webkit-transition:all 0.25s ease;transition:all 0.25s ease;height:2px;background-color:[lineColor];position:absolute;",
    "%_top-3": "top:0;width:50%;",
    "%_middle-3": "width:100%;top:9px;",
    "%_middle-4": "width:100%;top:9px;",
    "%_bottom-3": "width:50%;bottom:0;",
    "%_left-3": "left:0;",
    "%_right-3": "right:0;",
    "%_middle-3%_bar": "-webkit-transform-origin:center;transform-origin:center;",
    "%_middle-4%_bar": "-webkit-transform-origin:center;transform-origin:center;",
    "%_top-3%_bar%_left-3": "-webkit-transform-origin:0% 0%;transform-origin:0% 0%;",
    "%_top-3%_bar%_right-3": "-webkit-transform-origin:100% 0%;transform-origin:100% 0%;",
    "%_bottom-3%_bar%_left-3": "-webkit-transform-origin:0% 100%;transform-origin:0% 100%;",
    "%_bottom-3%_bar%_right-3": "-webkit-transform-origin:100% 100%;transform-origin:100% 100%;",
    "%_menu-button-3%_toggleOpen %_top-3%_bar%_left-3": "-webkit-transform:scaleX(0);transform:scaleX(0);background-color:[lineColorOpen];",
    "%_menu-button-3%_toggleOpen %_top-3%_bar%_right-3": "-webkit-transform:scaleX(0);transform:scaleX(0);background-color:[lineColorOpen];",
    "%_menu-button-3%_toggleOpen %_bottom-3%_bar%_left-3": "-webkit-transform:scaleX(0);transform:scaleX(0);background-color:[lineColorOpen];",
    "%_menu-button-3%_toggleOpen %_bottom-3%_bar%_right-3": "-webkit-transform:scaleX(0);transform:scaleX(0);background-color:[lineColorOpen];",
    "%_menu-button-3%_toggleOpen %_middle-3%_bar": "-webkit-transform:rotate(-45deg) scaleX(1);transform:rotate(-45deg) scaleX(1);background-color:[lineColorOpen];",
    "%_menu-button-3%_toggleOpen %_middle-4%_bar": "-webkit-transform:rotate(45deg) scaleX(1);transform:rotate(45deg) scaleX(1);background-color:[lineColorOpen];"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin4'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_menu"
        ],
        {},
        [
          "span",
          null,
          [
            "_menu-line",
            "_menu-line-1"
          ],
          {}
        ],
        [
          "span",
          null,
          [
            "_menu-line",
            "_menu-line-2"
          ],
          {}
        ],
        [
          "span",
          null,
          [
            "_menu-line",
            "_menu-line-3"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_15",
    "borderwidth": "0",
    "bordercolor": "color_15",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_15",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_15",
    "lineColor": "color_11",
    "lineColorOpen": "color_11"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;[rd]  [shd]  background-color:[bg];border:solid [borderwidth] [bordercolor];",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;[rdOpen]  [shdOpen]  background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];",
    "%_menu": "position:relative;width:22px;height:20px;margin:auto;",
    "%_menu-line": "background-color:[lineColor];height:2px;width:100%;border-radius:2px;position:absolute;left:0;-webkit-transition:all 0.25s ease-in-out;transition:all 0.25s ease-in-out;",
    "%_menu-line-2": "top:0;bottom:0;margin:auto;",
    "%_menu-line-3": "bottom:0;",
    "%_menu%_toggleOpen %_menu-line": "background-color:[lineColorOpen];",
    "%_menu%_toggleOpen %_menu-line-1": "-webkit-transform:translateY(10px) translateY(-50%) rotate(-45deg);transform:translateY(10px) translateY(-50%) rotate(-45deg);",
    "%_menu%_toggleOpen %_menu-line-2": "opacity:0;",
    "%_menu%_toggleOpen %_menu-line-3": "-webkit-transform:translateY(-10px) translateY(50%) rotate(45deg);transform:translateY(-10px) translateY(50%) rotate(45deg);"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin5'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_button_container"
        ],
        {},
        [
          "div",
          null,
          [
            "_name_container"
          ],
          {},
          [
            "span",
            "menu_name",
            [],
            {},
            "ME",
            [
              "br",
              null,
              [],
              {}
            ],
            "NU"
          ]
        ],
        [
          "span",
          "line_one",
          [],
          {}
        ],
        [
          "span",
          "line_two",
          [],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_button_container": "width:26px;height:26px;cursor:pointer;",
    "%_name_container": "opacity:1;-webkit-transition:opacity 0.5s ease;transition:opacity 0.5s ease;-webkit-touch-callout:none;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;",
    "%menu_name": "position:absolute;left:55%;top:50%;-webkit-transform:translate(-50%, -50%);transform:translate(-50%, -50%);color:[lineColor];font-family:\"Helvetica-bold\";font-size:12px;letter-spacing:5px;-webkit-transition:all .25s ease;transition:all .25s ease;",
    "%line_one,%line_two": "width:0px;height:2px;background:[lineColorOpen];position:absolute;top:50%;left:50%;opacity:0;",
    "%line_one": "-webkit-transform:translate(-50%, -50%) rotate(45deg);transform:translate(-50%, -50%) rotate(45deg);-webkit-transition:all .3s ease;transition:all .3s ease;",
    "%line_two": "-webkit-transform:translate(-50%, -50%) rotate(-45deg);transform:translate(-50%, -50%) rotate(-45deg);-webkit-transition:all .3s .3s ease;transition:all .3s .3s ease;",
    "%_button_container%_toggleOpen %line_one": "width:24px;opacity:1;",
    "%_button_container%_toggleOpen %line_two": "width:24px;opacity:1;",
    "%_button_container%_toggleOpen %_name_container": "opacity:0;"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin6'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_menu"
        ],
        {},
        [
          "div",
          null,
          [
            "_pix"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_pix"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_pix"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_pix"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_pix"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_pix"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_pix"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_pix"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_pix"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "*,*:before,*:after": "box-sizing:border-box;",
    "%_menu": "position:relative;display:block;width:22px;height:22px;",
    "%_menu %_pix": "position:absolute;width:4.4px;height:4.4px;background-color:[lineColor];border-radius:100%;-webkit-transition:0.2s ease-in-out;transition:0.2s ease-in-out;",
    "%_menu %_pix:nth-of-type(1)": "-webkit-transform:translate(0px, 0px);transform:translate(0px, 0px);",
    "%_menu %_pix:nth-of-type(2)": "-webkit-transform:translate(8.8px, 0px);transform:translate(8.8px, 0px);",
    "%_menu %_pix:nth-of-type(3)": "-webkit-transform:translate(17.6px, 0px);transform:translate(17.6px, 0px);",
    "%_menu %_pix:nth-of-type(4)": "-webkit-transform:translate(0px, 8.8px);transform:translate(0px, 8.8px);",
    "%_menu %_pix:nth-of-type(5)": "-webkit-transform:translate(8.8px, 8.8px);transform:translate(8.8px, 8.8px);",
    "%_menu %_pix:nth-of-type(6)": "-webkit-transform:translate(17.6px, 8.8px);transform:translate(17.6px, 8.8px);",
    "%_menu %_pix:nth-of-type(7)": "-webkit-transform:translate(0px, 17.6px);transform:translate(0px, 17.6px);",
    "%_menu %_pix:nth-of-type(8)": "-webkit-transform:translate(8.8px, 17.6px);transform:translate(8.8px, 17.6px);",
    "%_menu %_pix:nth-of-type(9)": "-webkit-transform:translate(17.6px, 17.6px);transform:translate(17.6px, 17.6px);",
    "%_menu%_toggleOpen %_pix": "background-color:[lineColorOpen];",
    "%_menu%_toggleOpen %_pix:nth-of-type(1)": "-webkit-transform:translate(0, 0);transform:translate(0, 0);",
    "%_menu%_toggleOpen %_pix:nth-of-type(2)": "-webkit-transform:translate(4.4px, 4.4px);transform:translate(4.4px, 4.4px);",
    "%_menu%_toggleOpen %_pix:nth-of-type(3)": "-webkit-transform:translate(17.6px, 0);transform:translate(17.6px, 0);",
    "%_menu%_toggleOpen %_pix:nth-of-type(4)": "-webkit-transform:translate(4.4px, 13.2px);transform:translate(4.4px, 13.2px);",
    "%_menu%_toggleOpen %_pix:nth-of-type(6)": "-webkit-transform:translate(13.2px, 4.4px);transform:translate(13.2px, 4.4px);",
    "%_menu%_toggleOpen %_pix:nth-of-type(7)": "-webkit-transform:translate(0, 17.6px);transform:translate(0, 17.6px);",
    "%_menu%_toggleOpen %_pix:nth-of-type(8)": "-webkit-transform:translate(13.2px, 13.2px);transform:translate(13.2px, 13.2px);",
    "%_menu%_toggleOpen %_pix:nth-of-type(9)": "-webkit-transform:translate(17.6px, 17.6px);transform:translate(17.6px, 17.6px);"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin7'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_hamburger"
        ],
        {},
        [
          "span",
          null,
          [],
          {}
        ],
        [
          "span",
          null,
          [],
          {}
        ],
        [
          "span",
          null,
          [],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_hamburger": "width:25px;height:20px;-webkit-transition:-webkit-transform 0.3s ease-in-out;transition:-webkit-transform 0.3s ease-in-out;transition:transform 0.3s ease-in-out;transition:transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;",
    "%_hamburger span": "content:\"\";display:block;position:relative;width:100%;height:3px;border-radius:1px;background-color:[lineColor];-webkit-transition:width 0.3s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;transition:width 0.3s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;transition:width 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in-out;transition:width 0.3s ease-in-out, transform 0.3s ease-in-out, opacity 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;",
    "%_hamburger span:nth-child(1)": "top:0;",
    "%_hamburger span:nth-child(2)": "top:5px;",
    "%_hamburger span:nth-child(3)": "top:10px;",
    "%_toggleOpen%_hamburger": "-webkit-transform:rotate(180deg);transform:rotate(180deg);",
    "%_toggleOpen%_hamburger span": "width:16px;background-color:[lineColorOpen];",
    "%_toggleOpen%_hamburger span:nth-child(1)": "opacity:0;",
    "%_toggleOpen%_hamburger span:nth-child(2)": "-webkit-transform:rotate(45deg) translateX(0px) translateY(1px);transform:rotate(45deg) translateX(0px) translateY(1px);",
    "%_toggleOpen%_hamburger span:nth-child(3)": "-webkit-transform:rotate(-45deg) translateX(12px) translateY(1px);transform:rotate(-45deg) translateX(12px) translateY(1px);"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin8'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_menu-btn"
        ],
        {},
        [
          "span",
          null,
          [],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_menu-btn": "display:block;width:24px;height:20px;position:relative;",
    "%_menu-btn span,%_menu-btn span::before,%_menu-btn span::after": "position:absolute;top:50%;margin-top:-1px;width:24px;height:2px;background-color:[lineColor];",
    "%_menu-btn span::before,%_menu-btn span::after": "content:'';-webkit-transition:0.2s;transition:0.2s;",
    "%_menu-btn span::before": "-webkit-transform:translateY(-9px);transform:translateY(-9px);",
    "%_menu-btn span::after": "-webkit-transform:translateY(9px);transform:translateY(9px);",
    "%_toggleOpen span": "background-color:[lineColorOpen];width:23px;-webkit-transform:translateX(1px);transform:translateX(1px);",
    "%_toggleOpen span:before": "-webkit-transform:rotate(-35deg);transform:rotate(-35deg);width:12px;-webkit-transform-origin:left bottom;transform-origin:left bottom;background-color:[lineColorOpen];",
    "%_toggleOpen span:after": "-webkit-transform:rotate(35deg);transform:rotate(35deg);width:12px;-webkit-transform-origin:left top;transform-origin:left top;background-color:[lineColorOpen];",
    "%_menu-block": "display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;",
    "%_menu-nav__link": "-webkit-transition:0.5s;transition:0.5s;-webkit-transform-origin:right center;transform-origin:right center;-webkit-transform:translateX(50%);transform:translateX(50%);opacity:0;",
    "%_menu-nav__link_active": "-webkit-transform:translateX(0%);transform:translateX(0%);opacity:1;"
  }
}
 skins['wysiwyg.viewer.skins.inlinePopupToggle.inlinePopupToggleSkin9'] = {
  "react": [
    [
      "div",
      "buttonContainer",
      [],
      {},
      [
        "div",
        "toggleButton",
        [
          "_menu-icon-box"
        ],
        {},
        [
          "div",
          null,
          [
            "_side-line",
            "_top"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_middle-line"
          ],
          {}
        ],
        [
          "div",
          null,
          [
            "_side-line",
            "_bottom"
          ],
          {}
        ]
      ]
    ]
  ],
  "params": {
    "rd": "BORDER_RADIUS",
    "shd": "BOX_SHADOW",
    "bg": "BG_COLOR_ALPHA",
    "borderwidth": "BORDER_SIZE",
    "bordercolor": "BORDER_COLOR_ALPHA",
    "rdOpen": "BORDER_RADIUS",
    "shdOpen": "BOX_SHADOW",
    "bgOpen": "BG_COLOR_ALPHA",
    "borderwidthOpen": "BORDER_SIZE",
    "bordercolorOpen": "BORDER_COLOR_ALPHA",
    "lineColor": "BG_COLOR_ALPHA",
    "lineColorOpen": "BG_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "rd": "0",
    "shd": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bg": "color_11",
    "borderwidth": "0",
    "bordercolor": "color_11",
    "rdOpen": "0",
    "shdOpen": "0 0 0 rgba(0, 0, 0, 0.6)",
    "bgOpen": "color_11",
    "borderwidthOpen": "0",
    "bordercolorOpen": "color_11",
    "lineColor": "#000000",
    "lineColorOpen": "#000000"
  },
  "css": {
    "%": "-webkit-tap-highlight-color:rgba(0, 0, 0, 0);",
    "%buttonContainer": "-webkit-transition:0.5s all;transition:0.5s all;[rd]  [shd]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bg];border:solid [borderwidth] [bordercolor];width:100%;height:100%;box-sizing:border-box;",
    "%buttonContainer%_toggleOpen": "[rdOpen]  [shdOpen]  display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-pack:center;-webkit-justify-content:center;justify-content:center;-webkit-box-align:center;-webkit-align-items:center;align-items:center;background-color:[bgOpen];border:solid [borderwidthOpen] [bordercolorOpen];width:100%;height:100%;box-sizing:border-box;",
    "%_menu-icon-box": "height:26px;width:26px;display:-webkit-box;display:-webkit-flex;display:flex;-webkit-box-align:center;-webkit-align-items:center;align-items:center;-webkit-justify-content:space-around;justify-content:space-around;-webkit-box-orient:vertical;-webkit-box-direction:normal;-webkit-flex-direction:column;flex-direction:column;-webkit-transition:-webkit-transform 200ms ease;transition:-webkit-transform 200ms ease;transition:transform 200ms ease;transition:transform 200ms ease, -webkit-transform 200ms ease;",
    "%_middle-line": "width:30px;height:2px;background-color:[lineColor];border-radius:10px;-webkit-transition:opacity 200ms ease, -webkit-transform 200ms ease;transition:opacity 200ms ease, -webkit-transform 200ms ease;transition:opacity 200ms ease, transform 200ms ease;transition:opacity 200ms ease, transform 200ms ease, -webkit-transform 200ms ease;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);",
    "%_side-line": "width:17px;height:2px;background-color:[lineColor];border-radius:10px;-webkit-transition:border-color 200ms ease, -webkit-transform 200ms ease;transition:border-color 200ms ease, -webkit-transform 200ms ease;transition:transform 200ms ease, border-color 200ms ease;transition:transform 200ms ease, border-color 200ms ease, -webkit-transform 200ms ease;",
    "%_side-line%_top": "-webkit-transform:rotate(-45deg) translate(-7px, -3px);transform:rotate(-45deg) translate(-7px, -3px);",
    "%_side-line%_bottom": "-webkit-transform:rotate(-45deg) translate(6px, 2px);transform:rotate(-45deg) translate(6px, 2px);",
    "%_menu-icon-box%_toggleOpen %_top": "width:20px;-webkit-transform:translateY(9px) rotate(135deg);transform:translateY(9px) rotate(135deg);background-color:[lineColorOpen];",
    "%_menu-icon-box%_toggleOpen %_bottom": "width:20px;-webkit-transform:translateY(-9px) rotate(45deg);transform:translateY(-9px) rotate(45deg);background-color:[lineColorOpen];",
    "%_menu-icon-box%_toggleOpen %_middle-line": "opacity:0;-webkit-transform:rotate(45deg);transform:rotate(45deg);"
  }
}

        return skins;
    }));