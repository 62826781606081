define([
    'lodash',
    'inlinePopup/components/inlinePopup'
], function (
    _,
    inlinePopup
) {
    'use strict';

    return _.assign({}, inlinePopup, {displayName: 'MenuContainer'});
});
