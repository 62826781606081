/**
 * Created by avim on 12/27/15.
 */
define([
    'lodash',
    'santa-components',
    'mediaContainer'
], function (
    _,
    santaComponents,
    mediaContainer
) {
    'use strict';

    const mediaContainerDefinition = mediaContainer.mediaContainerFactory.createMediaContainer({displayName: 'MediaContainer', useBackgroundDetectionMixin: false, isDomOnlyUpdateAllowed: false, fitToContentHeight: true}); //eslint-disable-line santa/no-module-state
    mediaContainerDefinition._getSkinProperties = mediaContainerDefinition.getSkinProperties;
    delete mediaContainerDefinition.getSkinProperties;

    function isDockedHorizontallyAndNotStretched(docked) {
        return (docked.right || docked.left) && !(docked.right && docked.left);
    }

    function getClosestAnchorAncestor(element) {
        if (!element || !element.tagName) {
            return null;
        }

        if (element.tagName.toLowerCase() === 'a') {
            return element;
        }

        return getClosestAnchorAncestor(element.parentNode);
    }

    const defaultAnimation = {
        enter: {
            name: 'FadeIn',
            delay: 0,
            duration: 0
        },
        exit: {
            name: 'FadeOut',
            delay: 0,
            duration: 0
        }
    };

    const defaultOverlayAnimation = {
        enter: {
            name: 'FadeIn'
        },
        exit: {
            name: 'FadeOut'
        }
    };

    function getAnimationDefinition(behaviors, isOpen) {
        const inBehavior = _.find(behaviors, {action: 'inlinePopupIn'});
        const outBehavior = _.find(behaviors, {action: 'inlinePopupOut'});
        if (inBehavior) {
            return isOpen ? inBehavior : _.assign({}, inBehavior, {name: outBehavior.name});
        }
        return isOpen ? defaultAnimation.enter : defaultAnimation.exit;
    }

    const InlinePopupMixin = {
        propTypes: {
            openInlinePopup: santaComponents.santaTypesDefinitions.InlinePopup.open,
            closeInlinePopup: santaComponents.santaTypesDefinitions.InlinePopup.close,
            isOpen: santaComponents.santaTypesDefinitions.InlinePopup.isOpen,
            componentViewMode: santaComponents.santaTypesDefinitions.RenderFlags.componentViewMode,
            siteScrollingBlocker: santaComponents.santaTypesDefinitions.SiteAspects.siteScrollingBlocker,
            registerToInnerScroll: santaComponents.santaTypesDefinitions.Scrollable.registerToInnerScroll,
            unregisterInnerScroll: santaComponents.santaTypesDefinitions.Scrollable.unregisterInnerScroll,
            behaviors: santaComponents.santaTypesDefinitions.Component.compBehaviors,
            currentUrlPageId: santaComponents.santaTypesDefinitions.currentUrlPageId,
            isHorizontallyDocked: santaComponents.santaTypesDefinitions.Component.isHorizontallyDocked,
            siteWidth: santaComponents.santaTypesDefinitions.siteWidth
        },

        getInitialState() {
            return {isVisible: this.props.isOpen};
        },

        componentDidMount() {
            this.props.siteScrollingBlocker.registerScrollingLayer(this.refs.inlineContentParent);
        },

        toggle(callbacks) {
            if (this.props.isOpen) {
                this.close(false, callbacks);
            } else {
                this.open(false, callbacks);
            }
        },

        open(immediate, callbacks, wixCodeCallback) {
            this.callbacks = callbacks;
            if (wixCodeCallback) {
                this.callbacks.onComplete = wixCodeCallback;
            }
            if (!this.props.isOpen || immediate) {
                this.immediate = this.immediate || immediate;
                this.props.openInlinePopup(this.props.id);
            } else {
                this.invokeCallbacksImmediately();
            }
        },

        close(immediate, callbacks, wixCodeCallback) {
            this.callbacks = callbacks;
            if (wixCodeCallback) {
                this.callbacks.onComplete = wixCodeCallback;
            }
            if (this.props.isOpen || immediate) {
                this.props.closeInlinePopup();
                this.immediate = this.immediate || immediate;
            } else {
                this.invokeCallbacksImmediately();
            }
        },

        invokeCallbacksImmediately() {
            _.invoke(this, 'callbacks.onStart', this.props.isOpen);
            _.invoke(this, 'callbacks.onComplete', this.props.isOpen);
            this.callbacks = {};
        },

        getAnimationToExecute(immediate) {
            return _.defaultsDeep(immediate ? {duration: 0, delay: 0} : {}, getAnimationDefinition(this.props.behaviors, this.props.isOpen));
        },

        onStartAnimation() {
            _.invoke(this, 'callbacks.onStart', this.props.isOpen);
        },

        onCompleteAnimation() {
            this.animateSkinParts({name: 'BaseClear', duration: 0, delay: 0, params: {props: 'clip,clipPath,webkitClipPath,opacity,transform,transformOrigin', immediateRender: false}}, {
                onComplete: () => {
                    if (this.props.isOpen && this.refs.inlineContent) {
                        this.refs.inlineContent.focus();
                    }
                    _.invoke(this, 'callbacks.onComplete', this.props.isOpen);
                    this.callbacks = {};
                    this.clearImmediate();
                }
            });
        },

        handleSiteScrollBlocking(isOpen) {
            if (isOpen) {
                this.props.registerToInnerScroll(this.props.id, '[id*=inlineContentParent]');
                this.props.siteScrollingBlocker.setSiteScrollingBlocked(this, true);
            } else {
                this.props.unregisterInnerScroll(this.props.id);
                this.props.siteScrollingBlocker.setSiteScrollingBlocked(this, false);
            }
        },

        handleAnimations() {
            const animation = this.getAnimationToExecute(this.immediate);

            if (this.props.isOpen === true && this.state.isVisible === false) {
                this.setState({isVisible: true}); // eslint-disable-line react/no-did-update-set-state
            }

            this.animateSkinParts(animation, {
                onStart: this.onStartAnimation,
                onComplete: () => {
                    if (!this.props.isOpen) {
                        this.setState({isVisible: false}); // eslint-disable-line react/no-did-update-set-state
                    }
                    this.onCompleteAnimation();
                }
            });
        },

        componentDidUpdate(prevProps) {
            const statusChanged = this.props.isOpen !== prevProps.isOpen;
            if (statusChanged || this.immediate) {
                if (statusChanged) {
                    this.handleSiteScrollBlocking(this.props.isOpen);
                }
                this.handleAnimations();
            }

            const pageIdChanged = prevProps.currentUrlPageId !== this.props.currentUrlPageId;
            if (this.props.isOpen && pageIdChanged) {
                this.close(true);
            }
        },

        animateSkinParts(animation, callbacks = {}) {
            window.requestAnimationFrame(() => {
                this.animateContainer(animation, callbacks);
                this.animateOverlay(animation);
            });
            this.clearImmediate();
        },

        clearImmediate() {
            this.immediate = false;
        },

        lastAnimation: null,
        animateContainer(animation, callbacks) {
            if (animation.duration > 0 && this.getSequence(this.lastAnimation)) {
                this.reverseSequence(this.lastAnimation);
            } else {
                this.lastAnimation = this.animate('container', animation.name, animation.duration, animation.delay, animation.params, _.assign({}, callbacks, {onReverseComplete: callbacks.onComplete}));
            }
        },

        lastOverlayAnimation: null,
        animateOverlay(containerAnimation) {
            if (this.props.isHorizontallyDocked) {
                return;
            }
            if (containerAnimation.duration > 0 && this.getSequence(this.lastOverlayAnimation)) {
                this.reverseSequence(this.lastOverlayAnimation);
            } else {
                const overlayAnimation = this.props.isOpen ? defaultOverlayAnimation.enter : defaultOverlayAnimation.exit;
                this.lastOverlayAnimation = this.animate('overlay', overlayAnimation.name, containerAnimation.duration, containerAnimation.delay, overlayAnimation.params);
            }
        },

        componentWillUnmount() {
            this.props.unregisterInnerScroll(this.props.id);
            this.props.siteScrollingBlocker.setSiteScrollingBlocked(this, false);
        },

        getRootStyle() {
            const rootStyle = {};
            // TODO: akaspi - fix for Bolt only. Should be written in Bolt's style fetcher
            const docked = _.get(this.props.structure, ['layout', 'docked'], {});
            if (isDockedHorizontallyAndNotStretched(docked)) {
                const dockedDirection = docked.right ? 'right' : 'left';
                rootStyle[dockedDirection] = `calc((100% - ${this.props.siteWidth}px) / 2)`;
            }            
            return _.assign(rootStyle, {
                position: 'fixed',
                zIndex: 1004,
                visibility: this.state.isVisible ? 'visible' : 'hidden',
                opacity: this.state.isVisible ? 1 : 0
            });
        },

        onClick(event) {
            // duplicate siteClickHandler logic and not extracting it due to Bolt side effect.
            const anchor = getClosestAnchorAncestor(event.target);
            if (anchor) {
                this.close(true);
            }
        },

        getSkinProperties() {
            const properties = {
                '': {
                    style: this.getRootStyle(),
                    onClick: this.onClick,
                    tabIndex: '0'
                },
                overlay: {
                    ref: 'overlay',
                    onClick: () => this.close(),
                    style: {
                        opacity: 0,
                        display: this.props.isHorizontallyDocked ? 'none' : 'initial'
                    }
                },
                container: {
                    ref: 'container',
                    style: {opacity: 0}
                }
            };
            
            return _.merge(mediaContainerDefinition._getSkinProperties.call(this), properties);
        }
    };

    const inlinePopupStatics = {
        behaviors: {
            toggle: {
                methodName: 'toggle',
                params: [{name: 'callbacks', defaultValue: {}}]
            },
            open: {
                methodName: 'open',
                params: [{name: 'immediate', defaultValue: false}, {name: 'callbacks', defaultValue: {}}]
            },
            close: {
                methodName: 'close',
                params: [{name: 'immediate', defaultValue: false}, {name: 'callbacks', defaultValue: {}}]
            },
            animate: {
                methodName: 'animateSkinParts',
                params: [{name: 'animation', defaultValue: defaultAnimation.enter}, {name: 'callbacks', defaultValue: {}}]
            }
        }
    };

    const inlinePopup = _.defaults({
        displayName: 'InlinePopup',
        mixins: [...mediaContainerDefinition.mixins, InlinePopupMixin, santaComponents.mixins.animationsMixin],
        statics: _.merge({}, mediaContainerDefinition.statics, inlinePopupStatics)
    }, mediaContainerDefinition);

    return inlinePopup;
});
